import { useEffect, useState } from "react";
import get from "lodash/get";

// helpers
import conversations from "@/api/query/conversations";


function useUnreadMessages(args?: { onConnectionCallback?: () => void, onNudgeMessage?: (data: string) => void, }) {
  const { onConnectionCallback, onNudgeMessage } = args || {};

  const [unreadMessages, setUnreadMessages] = useState<number | null>(null);

  const { connection: SOCKET_CONNECTION, initialData } = conversations.useListenNewMessages({
    open() {
      onConnectionCallback && onConnectionCallback();
    },
    message(event) {
      const data = JSON.parse(event.data);
      if (typeof data.message_count === "undefined") {
        return;
      }

      if (data.nudge && onNudgeMessage) {
        onNudgeMessage(data.nudge);
      }

      setUnreadMessages(data.message_count);
    },
    error() {
      SOCKET_CONNECTION && SOCKET_CONNECTION.close();
    },
  });

  useEffect(() => {
    if (SOCKET_CONNECTION && SOCKET_CONNECTION.readyState === 1) {
      return () => SOCKET_CONNECTION.close();
    }
  }, [SOCKET_CONNECTION]);

  useEffect(() => {
    if (!unreadMessages && get(initialData, "message_count")) {
      setUnreadMessages(get(initialData, "message_count", 0));
    }
  }, [JSON.stringify(initialData)]);

  return [unreadMessages, SOCKET_CONNECTION] as [null | number, WebSocket];
}

export default useUnreadMessages;
