import React from "react";
import get from "lodash/get";

export const getChildrenByDisplayName = (children: React.ReactNode, displayName: string) => {
  const filteredChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && typeof child.type !== "string") {

      return get(child, "type.displayName", "") === displayName ? child : null;
    }

    return null;
  });

  return filteredChildren && filteredChildren.length ? filteredChildren : null;
};

export const getOtherChildren = (children: React.ReactNode, displayNames: Array<string> = []) => {
  return React.Children.map(children, child => {
    if (React.isValidElement(child) && typeof child.type !== "string") {

      return displayNames.includes(get(child, "type.displayName", "")) ? null : child;
    }

    return child;
  });
};
