import React from "react";
import { Skeleton } from "antd";

// interfaces
import { InputSkeletonProps } from "./IInput";

// assets
import styles from "../../assets/styles/shared/input.module.scss";

const InputSkeleton = ({ label, ...props }: InputSkeletonProps) => {
  return (
    <div className={styles["locl-input-skeleton"]} {...props}>
      {label && <label className={styles["locl-input-skeleton__label"]}>{label}</label>}
      <Skeleton.Input active className={styles["locl-input-skeleton__field"]} />
    </div>
  );
};

export default InputSkeleton;
