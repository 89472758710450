import React from "react";

// components
import Link from "@/components/Link";

// assets
import styles from "@/assets/styles/components/Auth/auth-login-form-footer.module.scss";


const LoginFormFooter = () => {

  return (
    <div className={styles["locl-auth-login-form-footer"]} data-testid="locl-auth-login-form-footer">
      <div className={styles["locl-auth-login-form-footer__text"]}>
        Are you a location provider?
      </div>
      <Link href="/get-on-board" className={styles["locl-auth-login-form-footer__link"]}>
        List your space
      </Link>
    </div>
  );
};

export default LoginFormFooter;
