import React from "react";

// interfaces
import { IIconComponentProps } from "@/types/components/IconComponentProps";

const BookDemoIcon = (props: IIconComponentProps<HTMLSpanElement>) => (
  <span {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49846 0.246216C5.91267 0.246216 6.24846 0.582002 6.24846 0.996216V1.74684H13.7522V0.996216C13.7522 0.582002 14.088 0.246216 14.5022 0.246216C14.9164 0.246216 15.2522 0.582002 15.2522 0.996216V1.74684H15.4041C17.8065 1.74684 19.7541 3.6944 19.7541 6.09684V15.4037C19.7541 17.8062 17.8065 19.7537 15.4041 19.7537H4.59658C2.19414 19.7537 0.246582 17.8062 0.246582 15.4037V6.09684C0.246582 3.6944 2.19414 1.74684 4.59658 1.74684H4.74846V0.996216C4.74846 0.582002 5.08424 0.246216 5.49846 0.246216ZM4.74846 3.24684H4.59658C3.02257 3.24684 1.74658 4.52283 1.74658 6.09684V6.74892H18.2541V6.09684C18.2541 4.52283 16.9781 3.24684 15.4041 3.24684H15.2522V3.99747C15.2522 4.41168 14.9164 4.74747 14.5022 4.74747C14.088 4.74747 13.7522 4.41168 13.7522 3.99747V3.24684H6.24846V3.99747C6.24846 4.41168 5.91267 4.74747 5.49846 4.74747C5.08424 4.74747 4.74846 4.41168 4.74846 3.99747V3.24684ZM18.2541 8.24892H1.74658V15.4037C1.74658 16.9777 3.02257 18.2537 4.59658 18.2537H15.4041C16.9781 18.2537 18.2541 16.9777 18.2541 15.4037V8.24892Z"
        fill="currentColor"
      />
    </svg>
  </span>
);
export default BookDemoIcon;
