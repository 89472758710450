import * as Yup from "yup";
import { FormikHelpers } from "formik";
import get from "lodash/get";

// components
import notification from "@/components/notifications";

// helpers
import { REQUIRED } from "@/globals/schemasErrorMessage.constants";

// interfaces
import { ILoginFormValues } from "./ILoginForm";


export const initialValues = {
  email: null,
  password: null,
  rememberMe: true,
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().required(REQUIRED),
  password: Yup.string().nullable().required(REQUIRED),
  rememberMe: Yup.bool().nullable(),
});

export const errorParser = (
  errorData: Record<string, unknown>,
  formMethods: FormikHelpers<ILoginFormValues>
) => {
  if ("nonFieldErrors" in errorData) {
    notification({
      type: "error",
      placement: "bottomLeft",
      duration: 5,
      description: get(errorData, "nonFieldErrors[0]") as string,
    });

    return;
  }

  if ("unverifiedEmail" in errorData) {
    formMethods.setFieldError("email", get(errorData, "unverifiedEmail") as string);

    return;
  }

  if ("detail" in errorData) {
    notification({
      type: "error",
      placement: "bottomLeft",
      duration: 5,
      description: get(errorData, "detail") as string,
    });
  }
};
