import React from "react";

function CityIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2464 16.002V18.503"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="15.168"
        y="10.166"
        width="4.1684"
        height="5.83576"
        rx="2.0842"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.08188 16.8354V18.5028"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.58105 14.3343H10.5831"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.58105 11.8333H10.5831"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.58105 9.33237H10.5831"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2524 10.166V4.33026C17.2524 3.86983 16.8791 3.49658 16.4187 3.49658H8.49872C8.03829 3.49658 7.66504 3.86983 7.66504 4.33026V6.8313"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.49658 18.5031V7.66522C3.49658 7.20479 3.86983 6.83154 4.33026 6.83154H11.8334C12.2938 6.83154 12.6671 7.20479 12.6671 7.66522V18.5031"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66309 18.5028H19.3367"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CityIcon;
