import React from "react";

//interfaces
import { IIconComponentProps } from "@/types/components/IconComponentProps";


const BookingsIcon = (props: IIconComponentProps<HTMLSpanElement>) => {
  return (
    <span { ...props }>
      <svg width="25" height="24" viewBox="0 0 25 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0002 13.0002H16.5017" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.0002 13.0002H17.5021" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="3.49634" y="2.99609" width="18.0075" height="18.0075" rx="3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.5038 7.99826H3.49634" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.49801 1.99609V3.99693" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5022 1.99609V3.99693" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.49805 12.726L8.41743 13.5544L10.2682 11.8877" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.0002 17.0022H17.5021" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.49805 16.728L8.41743 17.5563L10.2692 15.8896" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
};
export default BookingsIcon;

