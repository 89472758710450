import { useQueryClient } from "@tanstack/react-query";

// helpers
import { useWebsocket } from "./reactQuery";
import { mapWhiteLabelWSQuery } from "@/helpers/mapWhiteLabelHeader";
import { WEBSOCKET_ROUTES } from "@/api/routes.constant";
import { IUseWebsocket } from "@/types/api/queryArgs";
import account from "@/api/query/account";
import conversation from "@/api/query/conversation";

const conversations = {
  useListenNewMessages(callbacks: IUseWebsocket["callbacks"]) {
    const queryClient = useQueryClient();

    const { data } = account.useIsAuthenticated();

    const connection = useWebsocket({
      socketUrl: WEBSOCKET_ROUTES.unreadMessagesCount,
      params: { token: data?.accessToken, ...mapWhiteLabelWSQuery() },
      config: { enabled: !!data?.accessToken },
      callbacks: {
        ...callbacks,
        message(event) {
          if (event) {
            queryClient.setQueryData(["hirer-message-list"], JSON.parse(event.data));

            callbacks && callbacks.message && callbacks.message(event);
          }
        },
      },
    });

    const initialData = queryClient.getQueryData(["hirer-message-list"])

    return {
      connection,
      initialData,
    }
  },
  
  useConversation(args?: {
    roomId?: number;
    onConnectionCallback?: () => void;
    // result of JSON.parse
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onMessage?: (data: any) => void;
  }) {
    const { roomId, onConnectionCallback, onMessage } = args || {};

    const { data } = account.useIsAuthenticated();
    const { mutateAsync } = conversation.useConversationsRoomSync({ roomId: roomId as number });

    return useWebsocket({
      socketUrl: roomId ? `${WEBSOCKET_ROUTES.conversations}/${roomId}` : WEBSOCKET_ROUTES.conversations,
      params: { token: data?.accessToken, ...mapWhiteLabelWSQuery() },
      config: { enabled: !!data?.accessToken },
      callbacks: {
        open: () => {
          if (roomId) {
            mutateAsync({ lastSync: new Date() })
              .then(() => onConnectionCallback && onConnectionCallback());
          }
        },
        message: (event) => {
          const message = JSON.parse(event.data);
          if (message.type !== "message") {
            return;
          }
    
          onMessage && onMessage(message);
          
          if (roomId) {
            mutateAsync({ lastSync: new Date() });
          }
        },
      },
    });
  }
}

export default conversations;
