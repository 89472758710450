import React from "react";

// interfaces
import { IIconComponentProps } from "@/types/components/IconComponentProps";

function SettingsIcon(props: IIconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.766 8.2324C12.7423 9.20871 12.7423 10.7916 11.766 11.7679C10.7897 12.7442 9.20678 12.7442 8.23047 11.7679C7.25416 10.7916 7.25416 9.20871 8.23047 8.2324C9.20678 7.25609 10.7897 7.25609 11.766 8.2324Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8472 2.1202L13.4302 2.3142C13.9642 2.4922 14.3252 2.9922 14.3252 3.5552V4.3922C14.3252 5.1042 14.8932 5.6852 15.6052 5.7002L16.4432 5.7182C16.9282 5.7282 17.3682 6.0072 17.5852 6.4412L17.8602 6.9912C18.1122 7.4952 18.0132 8.1032 17.6152 8.5012L17.0232 9.0932C16.5202 9.5962 16.5112 10.4092 17.0032 10.9232L17.5832 11.5292C17.9192 11.8802 18.0332 12.3872 17.8792 12.8482L17.6852 13.4312C17.5072 13.9652 17.0072 14.3262 16.4442 14.3262H15.6072C14.8952 14.3262 14.3142 14.8942 14.2992 15.6062L14.2812 16.4442C14.2712 16.9292 13.9922 17.3692 13.5582 17.5862L13.0082 17.8612C12.5042 18.1132 11.8962 18.0142 11.4982 17.6162L10.9062 17.0242C10.4032 16.5212 9.5902 16.5122 9.0762 17.0042L8.4702 17.5842C8.1192 17.9202 7.6122 18.0342 7.1512 17.8802L6.5682 17.6862C6.0342 17.5082 5.6732 17.0082 5.6732 16.4452V15.6082C5.6732 14.8962 5.1052 14.3152 4.3932 14.3002L3.5552 14.2822C3.0702 14.2722 2.6302 13.9932 2.4132 13.5592L2.1382 13.0092C1.8862 12.5052 1.9852 11.8972 2.3832 11.4992L2.9752 10.9072C3.4782 10.4042 3.4872 9.5912 2.9952 9.0772L2.4152 8.4712C2.0782 8.1192 1.9642 7.6112 2.1182 7.1512L2.3122 6.5682C2.4902 6.0342 2.9902 5.6732 3.5532 5.6732H4.3902C5.1022 5.6732 5.6832 5.1052 5.6982 4.3932L5.7162 3.5552C5.7282 3.0702 6.0062 2.6302 6.4402 2.4132L6.9902 2.1382C7.4942 1.8862 8.1022 1.9852 8.5002 2.3832L9.0922 2.9752C9.5952 3.4782 10.4082 3.4872 10.9222 2.9952L11.5282 2.4152C11.8792 2.0802 12.3872 1.9662 12.8472 2.1202Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

export default SettingsIcon;
