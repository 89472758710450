import { useFetch } from "./reactQuery";
import API_ROUTES from "@/api/routes.constant";

// helpers
import { REGIONS } from "@/helpers/regions.constant";

// interfaces
import { IAbstractRespListItem } from "@/types/Global";
import { ICurrency } from "@/types/api/Currency";


interface IGeographicRegion extends IAbstractRespListItem {
  code: string;
}

export interface IPlatform extends IAbstractRespListItem {
  label: string;
}

export default {
  useGeographicRegion: () => {
    return useFetch<IGeographicRegion[]>(API_ROUTES.geographicRegions, {}, {
      axiosConfig: {
        headers: {
          "Authorization": null,
        }
      },
      placeholderData: () => REGIONS.map((region) => ({ name: region.label, code: region.value.replace("/", ""), id: region.code })),
    });
  },
  useCurrenciesList: () => {
    return useFetch<ICurrency[]>(API_ROUTES.geographicRegions, {}, {
      axiosConfig: {
        headers: {
          "Authorization": null,
        }
      },
    });
  },
  usePlatforms: () => {
    return useFetch<IPlatform[]>(API_ROUTES.platforms);
  },
}
