import React from "react";

//interfaces
import { IIconComponentProps } from "@/types/components/IconComponentProps";

function UserProfile(props: IIconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2041_93778)">
          <path
            d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9886 8.3239C15.0871 9.42244 15.0871 11.2035 13.9886 12.3021C12.8901 13.4006 11.109 13.4006 10.0104 12.3021C8.91189 11.2035 8.91189 9.42244 10.0104 8.3239C11.109 7.22537 12.8901 7.22537 13.9886 8.3239Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.707 18.958C16.272 17.447 14.248 16.5 12 16.5C9.75197 16.5 7.72797 17.447 6.29297 18.959"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2041_93778">
            <rect width="24" height="24" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default UserProfile;
