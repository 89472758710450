export const REGIONS = [
  {
    label: "UK & Europe",
    value: "/uk",
    code: "uk"
  },
  {
    label: "Australia",
    value: "/aus",
    code: "aus"
  }
]
