"use client";

import React, { useState } from "react";
import classNames from "classnames";
import { Drawer } from "antd";
import { MdOutlineMenu } from "react-icons/md";

// components
import { HeaderProfile, HeaderLogo, HeaderMobileRight } from "./subComponents";

// helpers
import { useDimension } from "@/hooks/useDimensions";
import { getChildrenByDisplayName, getOtherChildren } from "@/helpers/subComponentsUtils";

// interfaces
import { IBaseHeader } from "./IBaseHeader";

// assets
import styles from "@/assets/styles/components/Header/base-header.module.scss"


function BaseHeader({ children, className, renderDrawerContent, ...rest }: IBaseHeader) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [width] = useDimension();
  const isTablet = !!width && width < 1200;

  const baseHeaderClassName = classNames(styles["locl-base-header"], "locl-base-header", className);
  const logo = getChildrenByDisplayName(children, HeaderLogo.displayName);
  const profile = getChildrenByDisplayName(children, HeaderProfile.displayName);
  const otherChildren = getOtherChildren(
    children,
    [HeaderLogo.displayName, HeaderProfile.displayName, HeaderMobileRight.displayName]
  );
  const mobileRightContent = getChildrenByDisplayName(children, HeaderMobileRight.displayName);


  return (
    <header className={baseHeaderClassName} { ...rest }>
      {logo}
      <div className={styles["locl-base-header__main-content"]}>
        { otherChildren }
      </div>
      <div className={styles["locl-base-header__profile"]}>
        {
          !!renderDrawerContent && isTablet
            ? (
              <>
                { mobileRightContent }
                <span
                  className={styles["locl-base-header__mobile-menu-button"]}
                  onClick={() => setIsDrawerOpen(true)}
                  data-testid="locl-base-header-menu-button"
                >
                  <MdOutlineMenu/>
                </span>
              </>
            )
            : profile
        }
      </div>
      {
        !!renderDrawerContent && isTablet && (
          <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            width="100vw"
            title={null}
            closeIcon={null}
            className={styles["locl-base-header__mobile-drawer"]}
            destroyOnClose
          >
            { !!renderDrawerContent && renderDrawerContent(() => setIsDrawerOpen(false)) }
          </Drawer>
        )
      }
    </header>
  );
}

BaseHeader.Logo = HeaderLogo;
BaseHeader.Profile = HeaderProfile;
BaseHeader.MobileRightContent = HeaderMobileRight;

export default BaseHeader;

