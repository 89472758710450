import React from "react";

//interfaces
import { IIconComponentProps } from "@/types/components/IconComponentProps";


function LogoutIcon(props: IIconComponentProps<HTMLSpanElement>) {

  return (
    <span { ...props }>
      <svg width="21" height="22" viewBox="0 0 21 22" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0034 11.0004L13.835 6.83203" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.835 15.1684L18.0034 11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.0035 10.9998H8.83301" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.83283 18.5037H6.88758C4.73891 18.5037 2.99707 16.7619 2.99707 14.6132V7.6659C2.99707 5.51723 4.73891 3.77539 6.88758 3.77539H8.83283" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
}

export default LogoutIcon;
