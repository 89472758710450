import React from "react";
import { InputNumber as InputNumberAntd } from "antd";
import classNames from "classnames";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

// interfaces
import { InputNumberProps } from "./IInput";

// components
import LabelErrorProvider from "../../components/LabelErrorProvider";

// assets
import styles from "../../assets/styles/shared/input.module.scss";

function InputNumber(props: InputNumberProps) {
  const { label, error, className, wrapperClassName, fullWidth, ...rest } = props;

  const inputNumberClassName = classNames(styles["locl-input-number"], className, {
    [styles["locl-input-number_full-width"]]: !!fullWidth
  });

  return (
    <LabelErrorProvider label={label} error={error} className={wrapperClassName}>
      <InputNumberAntd
        {...rest}
        className={inputNumberClassName}
        controls={{
          upIcon: (
            <FaChevronUp
              className={
                styles["locl-input-number__control-icon locl-input-number__control-icon_up"]
              }
            />
          ),
          downIcon: (
            <FaChevronDown
              className={
                styles["locl-input-number__control-icon locl-input-number__control-icon_down"]
              }
            />
          )
        }}
      />
    </LabelErrorProvider>
  );
}

export default InputNumber;
