export const REQUIRED = "Oops! This field is required!";

export const REQUIRED_WITH_COMA = "Oops, this field is required!";

export const REQUIRED_WITHOUT_OOPS = "This field is required";

export const REQUIRED_UPLOAD_FILE = "Oops! you need to upload a file";

export const REQUIRED_CAMPAIGN_NAME = "Campaign name required!";

export const REQUIRED_BRAND_NAME = "Brand name required!";

export const REQUIRED_BRIF_SUMMARY = "Brief summary required!";

export const REQUIRED_NOT_BE_BLANK = "This field may not be blank.";

export const REQUIRED_WHO_YOU_ARE = "Oops! Tell us who you are.";

export const REQUIRED_HOW_CONTACT = "Oops! Tell us how to contact you.";

export const REQUIRED_WHICH_COMPANY = "Oops! We need to know which Company you are from.";

export const INTEGER = "Oops! Please enter an integer!";

export const TEST_PERCENTAGES_SUM_NAME = "Check sum percentages";

export const TEST_PERCENTAGES_SUM_MESSAGE = "Sum must be 100%";

export const MIN_NUMBER_ERROR_MESSAGE = "Oops! Enter a valid number!";

export const requiredSelectField = (field: string) => `Select ${field}`;

export const maxCharacters = (number: string) => `Ensure this field has no more then ${number} characters!`;

export const filedTooShort = (field: string) => `${field} is too short!`;

export const fieldIsRequired = (field: string) => `${field} required!`;
